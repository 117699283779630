var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-container',{staticClass:"view-time-log-container"},[_c('h1',[_vm._v("儲值加值紀錄")]),_c('div',{staticClass:"income"},[_vm._l((Object.keys(_vm.incomeWithCompany)),function(company){return [(['1','4'].includes(_vm.currentUser.auth_id) || _vm.currentUser.admin_company === company)?_c('k-card',{key:("company_income_" + company),attrs:{"title":(company + " 總營收")}},[_c('k-icon',{attrs:{"icon":"coin"}}),_c('span',[_vm._v(_vm._s(_vm.incomeWithCompany[company]))])],1):_vm._e()]})],2),_c('k-card',{attrs:{"header":"紀錄"}},[_c('k-table',{attrs:{"items":_vm.timeLogWithCompany,"fields":_vm.fields,"hover":"","footerCaption":"","pagination":_vm.timeLogWithCompany.length > 10 ? _vm.pagination : false},scopedSlots:_vm._u([{key:"cell(user_id)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.userDict[value] ? _vm.userDict[value].user_account : '-')+" ")]}},{key:"cell(time_log_price)",fn:function(ref){
var value = ref.value;
return [_vm._v(" $"+_vm._s(value)+" ")]}},{key:"cell(time_log_archive)",fn:function(ref){
var value = ref.value;
return [_c('k-tag',{attrs:{"theme":value==='0' ? 'success' : 'danger'}},[_vm._v(_vm._s(value==='0' ? '已生效' : '已撤銷'))])]}},{key:"cell(time_log_company)",fn:function(ref){
var value = ref.value;
return [_c('k-tag',{attrs:{"theme":_vm.companyColorPlate[value]}},[_vm._v(_vm._s(value))])]}},{key:"cell(time_log_agent)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.adminDict[value] ? _vm.adminDict[value].admin_name : '-')+" ")]}},{key:"cell(time_log_value)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value / 3600 / 24)+" 天 ")]}},{key:"cell(time_log_create_at)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.timestampParse(value).format('YYYY-MM-DD'))+" ")]}},{key:"cell(action)",fn:function(data){return [(_vm.currentUser.edit_data === '1' && data.item.time_log_archive!=='1')?_c('k-button',{attrs:{"varient":"ghost","theme":"danger"},on:{"click":function($event){return _vm.revertLog(data.item)}}},[_vm._v("撤銷儲值")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }