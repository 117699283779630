import database from '@/utils/database'
import { genBasicModel } from './utils/basicAction'
import { initializeApp } from "firebase/app"
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth, updatePassword } from "firebase/auth"
import { fetchApi } from '../utils'

const MODEL_NAME = 'admin'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query)

async function query() {
  return await database.table(MODEL_NAME).orderBy('auth_id').get()
}

const FIREBASE_CONFIG = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}

// Initialize Firebase
const secondApp = initializeApp(FIREBASE_CONFIG)
const auth = getAuth(secondApp)

  // let secondaryApp = state.secondaryApp || firebase.initializeApp(config, "Secondary");
//   if (!state.secondaryApp) commit("saveSecondaryApp", secondaryApp);
//   return secondaryApp
// } 

export default {
  namespaced: true,
  state: {
    adminList: [],
    authList: [],
    timeLogList: []
  },
  mutations: {
    ...basicMutation,
    addAdmin(state, payload) {
      state.adminList.push({ ...payload })
    },
    saveSecondaryApp(state, payload) {
      state.secondaryApp = payload
    },
    saveTimeLogs(state, payload) {
      state.timeLogList = [...payload]
    },
    saveNewTimeLog(state, payload) {
      state.timeLogList = [{ ...payload }, ...state.timeLogList]
    },
    archiveAdmin(state, payload) {
      const index = state.adminList.map(a => a.admin_uid).indexOf(payload)
      state.adminList.splice(index, 1, { ...state.adminList[index], admin_archive: '1' })
    },
    deleteAdmin(state, payload) {
      const index = state.adminList.map(a => a.admin_uid).indexOf(payload)
      state.adminList.splice(index, 1)
    },
    recoverAdmin(state, payload) {
      const index = state.adminList.map(a => a.admin_uid).indexOf(payload)
      state.adminList.splice(index, 1, { ...state.adminList[index], admin_archive: '0' })
    },
    archiveTimeLog(state, payload) {
      const index = state.timeLogList.map(a => a.time_log_id).indexOf(payload)
      console.log(index);
      state.timeLogList.splice(index, 1, { ...state.timeLogList[index], admin_archive: '1' })
    }
  },
  actions: {
    ...basicAction,
    async queryAuth({ commit }) {
      const { state, data, exists } = await database.table('auth').get()
      if (state === 200 && exists) commit('save', { name: 'authList' , data})
    },
    async queryTimeLog({ commit }) {
      const res = await database.table('time_log').get()
      console.log(res);
      commit('saveTimeLogs', res.data)
    },
    async createNewAdmin({ commit, state, rootState }, payload) {
      try {
        const { admin_email } = payload
        const res = await fetchApi('/api/guid/')
        console.log(res);
        const uid = res.uid
        const response = await database.table('admin').set({
          ...payload,
          admin_uid: uid ,
          admin_is_confirm: false,
        })
        if (response.status === 200) {
          commit('addAdmin', { admin_uid: uid, admin_is_confirm: false , ...payload })
          return response
        }
      } catch (error) {
        console.log(error);
        return { error }
      }
    },
    async activeAdmin({ dispatch }, payload) {
      try {
        const { uid, admin_email, admin_name, password } = payload
        const userCredential = await createUserWithEmailAndPassword(auth, admin_email, password)
        const user = auth.currentUser;
        await updatePassword(user, password)
        await dispatch('login/updateUser', { uid, admin_uid:  auth.currentUser.uid, admin_name, admin_is_confirm: true }, { root: true })
        dispatch('queryAdmin')
        return true 
      } catch (error) {
        console.log(error);
      }
    },
    async getUserEmail(_, payload) {
      const response = await database.table('admin').where('admin_uid', '=', payload).get();
      console.log(response);
      return response
    },
    async addTimeLog({ commit }, payload) {
      const res = await database.table('time_log').set({ ...payload, time_log_create_at: database.FieldValue.serverTimestamp() })
      console.log(res);
      commit('saveNewTimeLog', payload)
      return res
    },
    async adminarchive({ commit }, payload) {
      const res = await database.table('admin').where('admin_uid', '=', payload).update({ admin_archive: '1' })
      console.log(res);
      commit('archiveAdmin', payload)
    },
    async adminDelete({ commit }, payload) {
      const res = await database.table('admin').where('admin_uid', '=', payload).delete()
      commit('deleteAdmin', payload)
    },
    async adminRecover({ commit }, payload) {
      const res = await database.table('admin').where('admin_uid', '=', payload).update({ admin_archive: '0' })
      console.log(res);
      commit('recoverAdmin', payload)
    },
    async revertUserLog({ commit, dispatch }, payload) {
      const { time_log_id, user_id, time_log_value } = payload
      const res = await database.table('time_log').where('time_log_id', '=', time_log_id).update({ time_log_archive: '1' })
      console.log(res);
      await dispatch('user/revertTime', { user_id, time_log_value }, { root: true })
      commit('archiveTimeLog', time_log_id)
    }
  },
  getters: {
    authDict(state) {
      return state.authList.reduce((acc, a) => ({ ...acc, [a.auth_id]: { ...a } }), {})
    },
    adminDict(state) {
      return state.adminList.reduce((acc, a) => ({ ...acc, [a.admin_id]: { ...a } }), {})
    },
    topLevelAdminIds(state) {
      return state.adminList.filter(a => ['1','2'].includes(a.auth_id)).map(e => e.admin_id)
    },
    agentDict(state) {
      return state.adminList.filter(a => a.auth_id === '3' && a.admin_is_confirm === '1').reduce((dict, a) => {
        console.log(a);
        return { ...dict, [a.admin_company]: [ ... dict[a.admin_company], { ...a }] }
      }, { 'AC01': [], 'AC02': [], 'AC03': [], 'AC04': [] })
    },
    timeLogWithCompany(state, getters, rootState, rootGetters) {
      console.log(rootState.login.currentUser);
      switch (rootState.login.currentUser.auth_id) {
        case '1': return state.timeLogList
        case '4': return state.timeLogList
        case '2': return state.timeLogList.filter(t => t.time_log_company === rootState.login.currentUser.admin_company)
        case '3': return state.timeLogList.filter(t => t.time_log_agent === rootState.login.currentUser.admin_id)
        default: return []
      }
    },
    adminWithCompany(state, getters, rootState, rootGetters) {
      switch (rootState.login.currentUser.auth_id) {
        case '1': return state.adminList
        case '4': return state.adminList
        case '2': return state.adminList.filter(t => t.admin_company === rootState.login.currentUser.admin_company)
        case '3': return state.adminList.filter(t => t.admin_agent === rootState.login.currentUser.admin_id)
        default: return []
      }
    },
    incomeWithCompany(state, getters) {
      console.log(getters.timeLogWithCompany);
      if (getters.timeLogWithCompany.length) {
        return getters.timeLogWithCompany.filter(log => log.time_log_archive !== '1').reduce((acc, log) => {
          if (acc[log.time_log_company]) acc[log.time_log_company] += parseInt( log.time_log_price)
          else acc[log.time_log_company] = parseInt(log.time_log_price)
          return acc
        }, {})
      }
      return {}
    }
  }
}
