import moment from 'moment'

export const COLOR_MAP = {
  primary: '#4638c2',
  secondary: '#4c4f54',
  success: '#45a164',
  danger: '#d16767',
  warning: '#e1a82d',
  info: '#4799eb',
  light: '#6c6e7e',
  dark: '#0e0e15',
  error: '#d16767'
}

export function range(start, stop, step=1) {
  if (arguments.length===1) {
    return Object.keys(Array(start).fill()).map(n => Number(n));
  }
  if (arguments.length >= 2) {
    let maxRange = Object.keys(Array(stop).fill())
    return maxRange.slice(start, maxRange.length ).map(n => Number(n)).filter(n => Number(n)%step === 0); 
  }
}

export function euqalObject(obj1, obj2) {
  let map1 = new Map(Object.entries(obj1));
  let map2 = new Map(Object.entries(obj2));
    
  if (map1.size !== map2.size) return false;
  for (let [key, val] of map1) {
    let val2 = map2.get(key);
    if (val !== val2 || (val2 === undefined && !map2.has(key)) ) return false;
  }
  return true;
}

export function arrayContainObject(arr, obj) {
  return arr.some(o => euqalObject(o, obj))
}

export function genFormData(obj) {
  return Object.entries(obj).reduce((acc, [key, val])=> {
    if (val) {
      if (Array.isArray(val) || (typeof val === 'object' && val !== null)) acc.append(key, JSON.stringify(val))
      else if (typeof val === 'boolean') acc.append(key, val === true ? '1' : '0')
      else acc.append(key, val)
    }
    return acc
  }, new FormData())
}

export function timestampParse(timestamp) {
  const stringifyTimeStamp = String(timestamp)
  if (stringifyTimeStamp.length > 10) return moment(timestamp)
  return moment(timestamp*1000)
}

export function fileSizeParse(size, type) {
  switch (type) {
    case 'Kb':
      return Math.round(size/1024, 2)
    case 'Mb':
      return Math.round(size/1024/1024, 2)
    case 'Gb':
      return Math.round(size/1024/1024/1024, 2)
    default:
      return size
  }
}

export function getRandom(min,max){
  return Math.floor(Math.random()*(max-min+1))+min;
}

export function gen8ID() {
  return 'xxxx-yxxx'.replace(/[xy]/g, c => {
    var r = Math.random()*16|0,v=c=='x'?r:r&0x3|0x8
    return v.toString(16)
  })
}


export function clearData(data) {
  return Object.assign({}, ...Object.entries(data).map(([key, val]) => (val && { [key]: val })))
}

export async function fetchApi(api, data, options={}) {
  let res;
  if (data) {
    const cleanData = clearData(data)
    const body = genFormData(cleanData)
    res = await fetch(api, { body, ...options, method: options.method || 'POST', headers: options.headers || {} })
  } else {
    res = await fetch(api, { headers: options.headers || {} })
  }

  let text = await res.text()
  try {
    const json = JSON.parse(text)
    return json
  } catch (error) {
    console.error(error);
    console.error(`response text: ${text}`);
    return
  }
}
