<template>
  <k-container class="view-time-log-container">
    <h1>儲值加值紀錄</h1>
    <div class="income">
      <template v-for="company in Object.keys(incomeWithCompany)">
        <k-card v-if="['1','4'].includes(currentUser.auth_id) || currentUser.admin_company === company" :title="`${company} 總營收`" :key="`company_income_${company}`">
          <k-icon icon="coin"></k-icon>
          <span>{{ incomeWithCompany[company] }}</span>
        </k-card>
      </template>
    </div>
    <k-card header="紀錄">
      <k-table :items="timeLogWithCompany" :fields="fields" hover footerCaption :pagination="timeLogWithCompany.length > 10 ? pagination : false">
        <template #cell(user_id)="{value}">
          {{ userDict[value] ? userDict[value].user_account : '-' }}
        </template>

        <template #cell(time_log_price)="{value}">
          ${{ value }}
        </template>

        <template #cell(time_log_archive)="{value}">
          <k-tag :theme="value==='0' ? 'success' : 'danger'">{{ value==='0' ? '已生效' : '已撤銷' }}</k-tag>
        </template>

        <template #cell(time_log_company)="{value}">
          <k-tag :theme="companyColorPlate[value]">{{ value }}</k-tag>
        </template>
        
        <template #cell(time_log_agent)="{value}">
          {{ adminDict[value] ? adminDict[value].admin_name : '-' }}
        </template>

        <template #cell(time_log_value)="{value}">
          {{ value / 3600 / 24 }} 天
        </template>

        <template #cell(time_log_create_at)="{value}">
          {{ timestampParse(value).format('YYYY-MM-DD') }}
        </template>

        <template #cell(action)="data">
          <k-button v-if="currentUser.edit_data === '1' && data.item.time_log_archive!=='1'" varient="ghost" theme="danger" @click="revertLog(data.item)">撤銷儲值</k-button>
        </template>
      </k-table>
    </k-card>
  </k-container>
</template>

<script>
import { KForm } from '@/components/Form'
import KCard from '@/components/Card'
import KDivider from '@/components/Divider'
import KResult from '@/components/Result'
import KButton from '@/components/Button'
import KTable from '@/components/Table'
import KTag from '@/components/Tag'
import { mapState, mapActions, mapGetters } from 'vuex'
import { timestampParse } from '../utils'

const fields = [
  { key: 'user_id', fieldIndex: 'user_id', label: '名稱' },
  { key: 'time_log_value', fieldIndex: 'time_log_value', label: '儲值時長' },
  { key: 'time_log_price', fieldIndex: 'time_log_price', label: '儲值金額' },
  { key: 'time_log_archive', fieldIndex: 'time_log_archive', label: '狀態', filters: [{ value: '1', text: '已註銷' }, { value: '0', text: '已生效' }]},
  { key: 'time_log_company', fieldIndex: 'time_log_company', label: '總代理',
    filters: [
      { value: 'AC01', text: 'AC01' }, 
      { value: 'AC02', text: 'AC02' }, 
      { value: 'AC03', text: 'AC03' }, 
      { value: 'AC04', text: 'AC04' },
    ]  
  },
  { key: 'time_log_agent', fieldIndex: 'time_log_agent', label: '代理' },
  { key: 'time_log_create_at', fieldIndex: 'time_log_create_at', label: '儲值日期', sorter: (a, b) => b - a },
  { key: 'action', fieldIndex: 'action', label: '' }, 
]

export default {
  async mounted() {
    await this.$store.dispatch('admin/queryTimeLog')
    if (!this.$store.state.user.userList.length) await this.$store.dispatch('user/query')
    if (!this.$store.state.admin.adminList.length) await this.$store.dispatch('admin/query')
  },
  data() {
    return {
      fields,
      companyColorPlate: { 'AC01': 'info', 'AC02': 'warning', 'AC03': 'success', 'AC04': 'primary' },
    }
  },
  methods: {
    ...mapActions('admin', ['revertUserLog']),
    timestampParse,
    revertLog(log) {
      const { time_log_id, user_id, time_log_value } = log
      this.$confirm.warning({
        title: "確定要撤銷此儲值紀錄嗎？",
        callback: async (ensure) => {
          if (ensure) {
            await this.revertUserLog({ time_log_id, user_id, time_log_value})
            this.$message.success('已撤銷此儲值紀錄，並歸還已加時間')
          }
        }
      })
    }
  },
  computed: {
    ...mapState('login', ['currentUser']),
    ...mapGetters('admin', ['timeLogWithCompany', 'adminDict', 'incomeWithCompany']),
    ...mapGetters('user', ['userDict'])
  },
  watch: {
    
  },
  components: {
    KForm, KCard, KDivider, KResult, KButton, KTable, KTag
  }
}
</script>
