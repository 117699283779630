<template>
  <header class="k-header">
    <nav>
      <ul class="k-header-links">
        <!-- <router-link to="/">
          <li>Dashboard</li>
        </router-link>
        <router-link to="/user">
          <li>Admin</li>
        </router-link> -->
        <!-- <router-link to="/CRM">CRM System</router-link> -->
        <li>管理帳號：{{ currentUser.admin_name }} | {{ authDict[currentUser.auth_id] ? authDict[currentUser.auth_id].auth_name : '-' }}</li>
      </ul>
      <ul class="k-header-notice">


        <li
          class="k-header-notice-item"
          v-if="currentUser"
        >
          <k-dropdown :header="`${currentUser.admin_name}`">
            <k-avatar :src="`./img/glovecoding.png`"></k-avatar>
            <k-menu slot="overlay">
              <k-menu-item @click="handleLogout">
                <k-icon
                  icon="door-open"
                  theme="light"
                />
                Logout
              </k-menu-item>
            </k-menu>
          </k-dropdown>
        </li>
      </ul>
    </nav>

    <!-- <BreadCrumbs /> -->
  </header>
</template>

<script>
import { KMenu, KMenuItem, KMenuTitle, KSubMenu, KMenuDivider } from '../../components/Menu';
import KDropdown from '../../components/Dropdown';
import KAvatar from '../../components/Avatar';
import BreadCrumbs from './BreadCrumbs'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  mounted() {
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      breadCrumbLink: []
    }
  },
  methods: {
    ...mapActions('login', ['logout']),
    ...mapActions('email', ['webmailLogout']),

    async handleLogout() {
      console.log('logout');
      const hasLogout = await this.logout()
      if (hasLogout) this.$router.push('/login')
    },
    async mailLogout() {
      this.webmailLogout()
      this.$router.push('/email/login')
    }
  },
  computed: {
    ...mapState('login', ['currentUser']),
    ...mapGetters('admin', ['authDict']),
    ...mapState('email', ['emailUser', 'emailTagList'])
  },
  components: {
    KMenu, KMenuItem, KMenuTitle, KSubMenu, KMenuDivider, KDropdown, KAvatar, BreadCrumbs
  }
}
</script>
