<template>
  <section class="k-sidebar">
      <div class="k-sidebar-brand"><slot name="brand"></slot></div>
      <k-menu class="menu-router" v-if="menus.length">
        <component v-for="item, index in menus" :is='componentFormat(item.type)' :key="`1_${index}`" :to="item.to">
          <template v-if="item.type==='item'">
            <k-icon v-if="item.icon" :icon="item.icon" />{{ item.label }}
          </template>
          
          <template v-if="item.type==='title'">
            {{ item.label }}
          </template>

          <template v-if="item.children && item.children.length">
            <span v-if="item.label" slot="title">
              <k-icon v-if="item.icon" :icon="item.icon" />
              {{ item.label }}
            </span>
            <component 
              v-for="child, childIndex in item.children"
              :is='componentFormat(child.type)' 
              :key="`2_${childIndex}`"
              :to="child.to"
            >
              <k-icon v-if="child.icon" :icon="child.icon" />{{ child.label }}
            </component>
          </template>
        </component>
        <!-- <k-menu-item><k-icon icon="speedometer2" />Dashboard</k-menu-item>
        <k-menu-title>BLOG</k-menu-title>
        <k-sub-menu>
          <span slot="title">
          <k-icon icon="gear" />
            文章管理
          </span>
          <k-menu-item to="/blog"><k-icon icon="file-earmark" />所有文章</k-menu-item>
          <k-menu-item to="/blog/create"><k-icon icon="pencil" />建立文章</k-menu-item>
          <k-menu-item><k-icon icon="diagram-3" />管理分類</k-menu-item>
        </k-sub-menu>

        <k-sub-menu>
          <span slot="title">
          <k-icon icon="search" />
            SEO 設定
          </span>
          <k-menu-item><k-icon icon="files" />管理模板</k-menu-item>
          <k-menu-item><k-icon icon="file-plus" />建立模板</k-menu-item>
        </k-sub-menu> -->
    </k-menu>
  </section>
</template>

<script>
import { KMenu, KMenuItem, KMenuTitle, KSubMenu, KMenuDivider } from '../../components/Menu';

export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    componentFormat(type) {
      const types = {
        'item': 'k-menu-item',
        'submenu': 'k-sub-menu',
        'title': 'k-menu-title'
      }
      return types[type]
    }
  },
  components: {
    KMenu, KMenuItem, KMenuTitle, KSubMenu, KMenuDivider
  }
}
</script>
