import Home from '../views/Home'
import Login from '../views/Login'
import Account from '../views/account'
import Admin from '../views/user/Admin'
import User from '../views/user'
import UserAdd from '../views/user/Add'
import Active from '../views/active'
import TimeLog from '../views/TimeLog'
import Register from '../views/Register'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/timelog',
    name: 'TimeLog',
    component: TimeLog
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/user/add',
    name: 'UserAdd',
    component: UserAdd
  },
  {
    path: '/active/:uid',
    name: 'Active',
    component: Active
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '*',
    redirect: '/login'
  }
]


export default routes;
