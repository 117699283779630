<template>
  <k-container class="view-admin-container">
    <h1>管理員帳號</h1>
    <AdminCard :onCopy="onCopy" />
    <!-- <k-collapse>
      <k-collapse-panel v-for="topAdmin in currentUser.auth_id === '1' ? topLevelAdminIds : [currentUser.admin_id]" :key="`admin_${topAdmin}`">
        <template #header>{{ adminDict[topAdmin].admin_name }} [{{ authDict[adminDict[topAdmin].auth_id].auth_name }}]</template>
        <k-collapse v-if="adminDict[topAdmin].children">
          <k-collapse-panel  v-for="adminL1 in adminDict[topAdmin].children " :key="`admin_${adminL1}`">
            <template #header>{{ adminDict[adminL1].admin_name }} [{{ authDict[adminDict[adminL1].auth_id].auth_name }}]</template>

            <k-collapse v-if="adminDict[adminL1].children">
              <k-collapse-panel  v-for="adminL2 in adminDict[adminL1].children " :key="`admin_${adminL2}`">
                <template #header>{{ adminDict[adminL2].admin_name }} [{{ authDict[adminDict[adminL2].auth_id].auth_name }}]</template>

                <k-collapse v-if="adminDict[adminL2].children">
                  <k-collapse-panel  v-for="adminL3 in adminDict[adminL2].children " :key="`admin_${adminL3}`">
                    <template #header>{{ adminDict[adminL3].admin_name }} [{{ authDict[adminDict[adminL3].auth_id].auth_name }}]</template>

                  </k-collapse-panel>
                </k-collapse>
              </k-collapse-panel>
            </k-collapse>
          </k-collapse-panel>
        </k-collapse>
      </k-collapse-panel>
    </k-collapse> -->
  </k-container>
</template>

<script>
import AdminCard from './components/AdminCard'
import { KCollapse, KCollapsePanel } from '@/components/Collapse';
import KCard from '@/components/Card'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: "Admin",
  async mounted() {
    this.$store.dispatch('admin/query')
  },
  computed: {
    ...mapState('login', ['currentUser']),
    ...mapGetters('admin', ['topLevelAdminIds', 'authDict', 'adminDict', 'adminTree']),
  },
  methods: {
    ...mapActions('admin', ['adminarchive']),
    onCopy() {
      this.$message.success('已複製到剪貼簿')
    },
    handleArchive(uid) {
      this.$confirm.info({
        title: "確定要將此帳號封存嗎？",
        callback: async (ensure) => {
          if (ensure) {
            await this.adminarchive(uid)
            this.$message.success('已封存')
          }
        }
      })
    }
  },
  components: {
    AdminCard, KCollapse, KCollapsePanel, KCard
  }
}
</script>
