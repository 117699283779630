import { genBasicModel } from './utils/basicAction'
import database from '@/utils/database'

const MODEL_NAME = 'user'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query)

async function query() {
  const res = await database.table(MODEL_NAME).get()
  return res
}

export default {
  namespaced: true,
  state: {
    userList: []
  },
  mutations: {
    ...basicMutation,
    saveNewExpTime(state, payload) {
      const { user_id, time } = payload
      const index = state.userList.map(e => e.user_id).indexOf(user_id)
      state.userList.splice(index, 1, { ...state.userList[index], user_exp_at: time })
    },
    updateUser(state, payload) {
      const index = state.userList.map(e => e.user_id).indexOf(payload.user_id)
      state.userList.splice(index, 1, { ...state.userList[index], ...payload })
    },
    createUser(state, payload) {
      state.userList = [{ ...payload }, ...state.userList]
    }
  },
  actions: {
    ...basicAction,
    async userAddExpTime({ commit }, payload) {
      const { user_id, time } = payload
      console.log(user_id, time);
      const res = await database.table('user').where('user_id', '=', user_id).update({ user_exp_at: time })
      console.log(res);
      commit('saveNewExpTime', payload)
      return res
    },
    async updateUserInfo({ commit }, payload) {
      const { user_exp_at, key_id, user_login_token, user_token_refresh_at, ...data } = payload
      const res = await database.table('user').where('user_id', '=', data.user_id).update({ ...data})
      commit('updateUser', data)
    },
    async revertTime({ commit, state }, payload) {
      const { user_id, time_log_value } = payload
      const userIndex = state.userList.map(u => u.user_id).indexOf(user_id)
      const oldExpTime =  state.userList[userIndex].user_exp_at
      const newExpTime = oldExpTime - time_log_value
      const res = await database.table('user').where('user_id', '=', user_id).update({ user_exp_at: newExpTime })
      console.log(res);
      commit('updateUser', { user_id, user_exp_at: newExpTime })
    },
    async registerUser({ commit }, payload) {
      const { account, password, lineId } = payload
      const check = await database.table('user').where('user_account', '=', account).get()
      console.log(check);

      if (check.data.length) {
        return { status: 422 }
      }
      const res = await database.table('user').set({ user_account: account, user_password: password, user_line_id: lineId || undefined })
      console.log(res);
      commit('createUser', { user_id: res.id, ...res.data })
      return res
    }
  },
  getters: {
    userDict(state) {
      return state.userList.reduce((acc, u) => ({ ...acc, [u.user_id]: { ...u } }), {})
    },
    userWithCompany(state, getters, rootState, rootGetters) {
      if (rootState.login.currentUser) {
        switch (rootState.login.currentUser.auth_id) {
          case '1': return state.userList
          case '4': return state.userList
          case '2': return state.userList.filter(t => t.user_company === rootState.login.currentUser.admin_company)
          case '3': return state.userList.filter(t => t.user_agent === rootState.login.currentUser.admin_id)
          default: return []
        }
      }
      return []
    }
  }
}
