<template>
  <section class="user-admin-card" v-if="adminList.length && Object.keys(authDict).length">
    <k-card v-if="currentUser">
      <template #header v-if="currentUser.auth_id !== '4'">
        <p>Admin Management</p>
        <k-button theme="info" varient="outline" @click="toUserAdd">新增管理者</k-button>
      </template>

      <k-table :items="adminWithCompany" :fields="fields" hover footerCaption :pagination="adminWithCompany.length > 10 ? pagination : false">
        <template #cell(admin_name)="data">
          {{ data.value || '尚未設定' }}
          <k-tag v-if="data.item.admin_archive === '1'" theme="danger">已封存</k-tag>
        </template>
        <template #cell(admin_email)="data">
          <span style="margin-right: 8px">{{ data.value }}</span>
          <k-icon style="cursor: pointer" v-copy="data.value" v-copy:success="onCopy" icon="clipboard"></k-icon>
        </template>

        <template #cell(admin_company)="{value}">
          <k-tag :theme="companyColorPlate[value]">{{ value }}</k-tag>
        </template>

        <template #cell(auth_id)="data">
          {{ authDict[data.value].auth_name }}
          <k-tag v-if="data.item.admin_is_confirm==='0'" theme="light">尚未激活</k-tag>
        </template>

        <template #cell(action)="data">
            <k-button
              v-if="parseInt(currentUser.auth_id) < parseInt(data.item.auth_id) && data.item.admin_archive === '0'"
              theme="danger"
              varient="ghost"
              @click="handleArchive(data.item.admin_uid)"
            >封存</k-button>
            <k-button
              v-if="parseInt(currentUser.auth_id) < parseInt(data.item.auth_id) && data.item.admin_archive === '1'"
              theme="info"
              varient="ghost"
              @click="handleRecover(data.item.admin_uid)"
            >復職</k-button>
            <k-button
              theme="light" varient="ghost"
              v-if="currentUser.auth_id === '1' && parseInt(currentUser.auth_id) < parseInt(data.item.auth_id)"
              @click="handleDelete(data.item.admin_uid)"
            >刪除</k-button>
        </template>
      </k-table>
    </k-card>
  </section>
</template>

<script>
import KTable from '@/components/Table'
import KCard from '@/components/Card'
import KTag from '@/components/Tag'
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import KPopconfirm from '@/components/Popconfirm'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: {
    onCopy: Function
  },
  data() {
    return {
      fields,
      companyColorPlate: { 'AC01': 'info', 'AC02': 'warning', 'AC03': 'success', 'AC04': 'primary' },
      pagination: {
        current: 1,
        limit: 10
      },
    }
  },
  methods: {
    ...mapActions('admin', ['adminarchive', 'adminDelete', 'adminRecover']),
    toUserAdd() {
      this.$router.push('/user/add')
    },
    handleArchive(uid) {
      this.$confirm.info({
        title: "確定要將此帳號封存嗎？",
        callback: async (ensure) => {
          if (ensure) {
            await this.adminarchive(uid)
            this.$message.success('已封存')
          }
        }
      })
    },
    handleDelete(uid) {
      this.$confirm.warning({
        title: "確定要將此帳號『刪除』嗎？",
        callback: async (ensure) => {
          if (ensure) {
            await this.adminDelete(uid)
            this.$message.success('已刪除')
          }
        }
      })
    },
    handleRecover(uid) {
      this.$confirm.info({
        title: "確定要將此封存帳號復原嗎？",
        callback: async (ensure) => {
          if (ensure) {
            await this.adminRecover(uid)
            this.$message.success('已復原')
          }
        }
      })
    }
  },
  computed: {
    ...mapState('login', ['currentUser']),
    ...mapState('admin', ['adminList']),
    ...mapState('login', ['currentUser']),
    ...mapGetters('admin', ['authDict', 'adminWithCompany']),
    lowerAuthAdmin() {
      if (this.currentUser && this.adminList.length) {
        return this.adminList.filter(a => {
          console.log(a);
          return parseInt(this.authDict[a.auth_id].auth_order) > parseInt(this.currentUser.auth_order)
        })
      }
    }
  },
  components: {
    KTable, KCard, KTag, KButton, KModal, KPopconfirm
  }
}

const fields = [
  { key: 'admin_name', fieldIndex: 'admin_name', label: '名稱', sorter: (a, b) => ('' + a).localeCompare(b), searchable: true },
  { key: 'admin_email', fieldIndex: 'admin_email', label: '信箱', searchable: true },
  { key: 'admin_company', fieldIndex: 'admin_company', label: '總代理',
    filters: [
            { value: 'AC01', text: 'AC01' }, 
            { value: 'AC02', text: 'AC02' }, 
            { value: 'AC03', text: 'AC03' }, 
            { value: 'AC04', text: 'AC04' }
          ]  
  },
  { key: 'auth_id', fieldIndex: 'auth_id', label: '權限', sorter: (a, b) => b - a, filters: [
    { text: '超級管理員', value: '1' },
    { text: '總代理', value: '2' },
    { text: '代理', value: '3' },
  ] },
  { key: 'action', fieldIndex: 'action', label: '編輯' }, 
]
</script>
