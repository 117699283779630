<template>
  <form
    ref="k-form"
    :class="classes"
    @submit.prevent="handleSubmit"
  >
    <slot></slot>
    <template v-if="questionConfig.length">
      <template v-for="question, index in questionConfig">
        <slot :name="`before(${question.name})`"></slot>
        <component
          v-if="question.questionType !== 'k-radio-group' && question.questionType !== 'k-upload' && question.type !== 'submit'"
          :key="index"
          :is="question.questionType"
          v-model="question.value"
          v-bind="{ ...question, loading: question.questionType === 'k-btn' ? submitLoading : null }"
          @change="question.onChange || void 0"
        >
        </component>

        <component
          :is="question.questionType"
          v-if="question.questionType === 'k-radio-group' && question.radioType === 'button'"
          :radioType="'button'"
          v-bind="question"
          :key="index"
          @onChange="question.onChange"
        >
          <!-- {{ question }} -->
          <k-radio-btn
            v-for="item, index in question.options"
            :key="'kr-' + index"
            :value="item.value"
          >
            {{ item.label }}
          </k-radio-btn>
        </component>

        <component
          :is="question.questionType"
          v-if="question.questionType === 'k-upload'"
          v-bind="question"
          :key="index"
          v-model="question.value"
          @change="question.onChange"
        >
          <img
            v-if="question.imgSrc"
            :src="question.imgSrc"
            :alt="question.alt || null"
          >
          <div
            v-else
            :class="{ 'k-upload-button': true, 'block': !!question.block, 'circle': !!question.circle }"
          >
            <k-icon icon="plus"></k-icon>
            <span>{{ question.placeholder || (question.droppable ? '將檔案拖拉至此處' : '點選此處上傳檔案') }}</span>
          </div>
        </component>

        <component
          :is="question.questionType"
          v-if="question.type === 'submit'"
          v-bind="question"
          :key="index"
          :loading="hasSubmitted"
        />
        <slot :name="`after(${question.name})`"></slot>
      </template>
    </template>
    <template>

    </template>
  </form>
</template>

<script>
import KCoder from './KCoder';
import KInput from './KInput';
import KTextarea from './KTextarea';
import KSwitch from './KSwitch';
import KSelect from './KSelect';
import KDatePicker from './KDatePicker';
import KRangePicker from './KRangePicker.vue';
import KRadioGroup from './KRadioGroup';
import KRadioButton from './KRadioButton';
import KButton from '../Button';
import KEditor from './KEditor';
import KUpload from './KUpload';
import { euqalObject } from '@/utils'

export default {
  props: {
    questionConfig: {
      type: Array,
      default: () => []
    },
    defaultValues: {
      type: Object,
      default: () => ({})
    },
    submitLoading: Boolean
  },
  data() {
    return {
      tags: ['input'],
      hasSubmitted: false,
    }
  },
  computed: {
    classes() {
      return {
        'k-form': true,
        'submitted': this.hasSubmitted
      }
    },
  },
  methods: {
    submit() {
      const result = this.$refs['k-form'].checkValidity()
      if (result) this.handleSubmit()
      else {
        this.$refs['k-form'].reportValidity()
      }
    },
    handleSubmit() {
      const formData = this.validation();
      this.hasSubmitted = true
      this.$emit('submit', formData);
    },
    validation() {
      var fileList = {}
      var existFileList = []
      const data = this.questionConfig.filter(q => q.name && q.type !== 'submit').reduce((acc, q) => {
        //如果為 k-upload，先將檔案裝進 fileList，再將檔名回傳
        if (q.questionType === 'k-upload') {
          const files = q.value ? Array.from(q.value).map(f => f.name) : []
          if (q.required && !files.length) return { ...acc, [q.name]: null }
          if (files.length) fileList = { ...fileList, [q.name]: [...q.value] };
          return { ...acc, [q.name]: ['', undefined, null].includes(q.value) ? undefined : files }
        }
        //如果為 k-editor，先解析富文本編輯器的 html 提取實際有使用的 img，並與上傳的檔案列表比對過濾，將有使用到的檔案加入 fileListxw
        if (q.questionType === 'k-editor') {
          console.log(q.value);
          let dom = new DOMParser().parseFromString(`<body>${q.value.html}</body>`, "text/html")
          const realImgSrcInDom = Array.from(dom.getElementsByTagName('img')).map(e => {
            let previewSrc = e.getAttribute("src");
            console.log(previewSrc);
            let fileIndex = q.value.fileList.map(f => f.previewSrc).indexOf(previewSrc)
            console.log(fileIndex);
            if (fileIndex !== -1) {
              e.setAttribute("src", `${q.filePath}/${q.value.fileList[fileIndex].file.name}`)
            } else {
              existFileList.push(previewSrc)
            }
            return previewSrc
          })
          const realUsedFileList = q.value.fileList ? q.value.fileList.filter(f => realImgSrcInDom.includes(f.previewSrc)).map(f => f.file) : []
          fileList = Object.assign({ ...fileList }, realUsedFileList.length && { [q.name]: [...realUsedFileList] })
          return { ...acc, [q.name]: ['', undefined, null].includes(q.value) ? null : dom.body.innerHTML }
        }
        if (q.required) return { ...acc, [q.name]: ['', undefined, null].includes(q.value) ? null : q.value }

        return { ...acc, [q.name]: ['', undefined, null].includes(q.value) ? undefined : q.value }
      }, {})
      if (Object.values(data).some(i => i === null)) {
        this.$message.error('有欄位尚未填寫！', 5000)
        return
      }
      return { values: data, fileList, existFileList }
    }
  },
  watch: {
    defaultValues: {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal && euqalObject(newVal, oldVal)) return
        if (Object.keys(newVal).length && this.questionConfig.length) {
          const questionsWithDefaultValue = this.questionConfig.map(q => {
            if (newVal[q.name]) {
              switch (q.questionType) {
                case 'k-upload': return { ...q, imgSrc: newVal[q.name] }
                case 'k-editor': return {
                  ...q,
                  defaultValue: newVal[q.name],
                  value: { html: newVal[q.name], fileList: [] }
                }
                case 'k-coder': return {
                  ...q,
                  defaultValue: newVal[q.name],
                  value: newVal[q.name]
                }
                default: return { ...q, value: newVal[q.name] || null };
              }
            }
            return q
          })
          this.$emit('update:questionConfig', questionsWithDefaultValue)
        }
      }
    }
  },
  components: {
    'k-coder': KCoder,
    'k-input': KInput,
    'k-switch': KSwitch,
    'k-btn': KButton,
    'k-radio-group': KRadioGroup,
    'k-radio-btn': KRadioButton,
    'k-select': KSelect,
    'k-date-picker': KDatePicker,
    'k-range-picker': KRangePicker,
    'k-editor': KEditor,
    'k-upload': KUpload,
    'k-textarea': KTextarea
  }
}
</script>
