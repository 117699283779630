import { genBasicModel } from './utils/basicAction'
import { create, update } from './utils/basicService'
import { createReverseLogisticsOrder } from '@/utils/ecpay/ecpay.js'
import { copySheet, connectSheet, updateSheet } from '@/utils/googleSheet'
import { TapPayRefund } from "@/utils/tappay.js"
import { fetchApi } from '@/utils'
import ecpayLogisticsState from "@/utils/ecpay/ecpayLogisticsState.json"
import database from '@/utils/database'
import moment from 'moment'

const MODEL_NAME = 'orders'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  query,
  create(MODEL_NAME, true),
  update(MODEL_NAME))

async function query() {
  const res = await database.table(MODEL_NAME)
    .join('return_order', 'orders_id')
    .join('order_log', 'orders_id')
    .join('user', 'user_id')
    .select([
      "orders.*",
      "user.user_id, GROUP_CONCAT(order_log.order_log_operation SEPARATOR ',') AS order_log_operations", 
      "GROUP_CONCAT(order_log.order_log_create_at SEPARATOR ', ') AS order_log_times"
    ])
    .groupBy('orders.orders_id')
    .orderBy('orders.order_create_at', 'desc')
    .get()
    console.log(res);
  let orderList = res.data.map(o => {
    const order_products = JSON.parse(o.order_products)
    const order_discount = -1*(order_products.reduce((acc,p)=>acc+p.product_price*p.product_quantity, 0) - o.order_amount - o.order_voucher_used)
    return {
      ...o,
      order_logistics_state: JSON.parse(o.order_logistics_state) || [],
      order_coupon_used: JSON.parse(o.order_coupon_used) || [],
      order_logistics_info: JSON.parse(o.order_logistics_info),
      order_logistics_cvs_info: JSON.parse(o.order_logistics_cvs_info),
      order_return_info: JSON.parse(o.order_return_info),
      order_shipping_fee: (parseInt(o.order_amount) + order_discount) >= 1000 ? 0 : 120,
      order_log_operations: o.order_log_operations ? o.order_log_operations.split(',') : [],
      order_products,
      order_discount
    }
  })
  const result = await fetchApi("/api/ecpay/getLogisticsState/", { orders: orderList.filter(o => {
    if (o.order_pay_type === "COD") return false 
    if (!o.order_logistics_state.length) return true
    let newestState = o.order_logistics_state[o.order_logistics_state.length-1].code
    switch (o.order_logistics_subtype) {
      case "TCAT": return !["3003", "20000"].includes(newestState)
      case "UNIMARTC2C": return !["2067", "20000"].includes(newestState)
      case "FAMIC2C": return !["3022", "20000"].includes(newestState)
    }
  }).map(o => ({
    MerchantTradeNo: o.order_logistics_id,
    TimeStamp: moment().unix()
  }))})
  const logisticsStateStoreRule = Object.keys(ecpayLogisticsState).reduce((acc, type) => ({
    ...acc, [type]: Object.keys(ecpayLogisticsState[type])
  }), logisticsStateStoreRule)
  res.data = result.response.reduce((acc, state) => {
    let index = acc.map(o => o.order_logistics_id).indexOf(state.MerchantTradeNo)
    let logisticsSubtype = state.LogisticsType.split("_")[1]
    acc.splice(index, 1, {
        ...acc[index],
      order_logistics_state: [
        ...acc[index].order_logistics_state,
        { code: state.LogisticsStatus }
      ].sort((a, b) => logisticsStateStoreRule[logisticsSubtype].indexOf(a.code) - logisticsStateStoreRule[logisticsSubtype].indexOf(b.code))
    })
    return acc
  }, orderList)
  return res
}

function setAttributes(el, attrs) {
  for(var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

export default {
  namespaced: true,
  state: {
    ordersList: [],
    returnList: []
  },
  mutations: {
    ...basicMutation,
    batchChangeState(state, { id_array, ...updateData }) {
      let orders_id_list = state.ordersList.map(e => e.key_id)
      id_array.forEach(id => {
        let index = orders_id_list.indexOf(id)
        state.ordersList.splice(index, 1, { ...state.ordersList[index], ...updateData })
      })
    },
    batchLogOperation(state, { orders, operation }) {
      let orders_id_list = state.ordersList.map(e => e.key_id)
      orders.forEach(o => {
        let index = orders_id_list.indexOf(o.orders_id)
        state.ordersList.splice(index, 1, {
          ...state.ordersList[index],
          order_log_operations: [...state.ordersList[index].order_log_operations, operation],
          order_logistics_state: operation === 'SHIPPED'
            ? [...state.ordersList[index].order_logistics_state, { code:"900", update_time: moment().format("YYYY\/MM\/DD HH:mm:ss") }]
            : state.ordersList[index].order_logistics_state
      })
      })
    }
  },
  actions: {
    ...basicAction,
    async queryReturns({ commit }) {
      const res = await database.table('return_order')
        .join('orders', 'orders_id')
        .join('user', 'user_id', 'orders', 'user_id')
        .orderBy('return_order.return_order_create_at', 'desc')
        .get()
      res.data = res.data.map(o => ({
        ...o,
        order_logistics_state: JSON.parse(o.order_logistics_state) || [],
        order_products: JSON.parse(o.order_products),
        order_coupon_used: JSON.parse(o.order_coupon_used),
        order_logistics_info: JSON.parse(o.order_logistics_info),
        order_logistics_cvs_info: JSON.parse(o.order_logistics_cvs_info)
      }))
      commit('save', { name: 'returnList', data: res.data })
    },
    async batchCheckState({ commit }, payload) {
      const { id_array, orders_dealer_id } = payload
      await database.table(MODEL_NAME).where('orders_id', 'in', id_array).update({ orders_state: 'COMPLETE', orders_dealer_id })
      commit('batchChangeState', { id_array, orders_state: 'COMPLETE', orders_dealer_id })
    },
    async confirmReturnApply({ commit, dispatch }, payload) {
      const res = await createReverseLogisticsOrder(payload)
      const returnOrderRes = await database.table('return_order')
        .where('orders_id', '=', payload.orders_id)
        .update({ return_order_state: 'RETURN_CONFIRM' })
      const databaseRes = await dispatch('update', { id: payload.orders_id, order_type: 'RETURN' })
      commit('update', { name: 'ordersList', data: { id: payload.orders_id, order_type: 'RETURN' } })
      commit('update', { name: 'returnList', data: { id: payload.return_order_id, return_order_state: 'RETURN_CONFIRM' } })
      console.log(res);
      console.log(returnOrderRes);
      console.log(databaseRes);
      return res
    },
    async refundOrder({ commit, dispatch }, order) {
      // const res = await TapPayRefund({ rec_trade_id: order.order_rec_trade_id })
      const returnOrderRes = await database.table('return_order')
        .where('orders_id', '=', order.orders_id)
        .update({ return_order_state: 'REFUNDED' })
      const databaseRes = await dispatch('update', { id: order.orders_id, order_type: 'REFUNDED' })
      commit('update', { name: 'ordersList', data: { id: order.orders_id, order_type: 'REFUNDED' } })
      commit('update', { name: 'returnList', data: { id: order.return_order_id, return_order_state: 'REFUNDED' } })
      // return res
    },
    async genPortSheet({ commit, rootGetters }, payload) {
      const { orders_id } = payload
      const title = `${orders_id} | ${payload.order_logistics_info.ReceiverName} 出貨單`
      console.log(genOrderPortCells(payload, rootGetters['product/productDict'], rootGetters['product/productUidDict']));
      const doc = await connectSheet(process.env.VUE_APP_ECOMMERCE_PORT_DOC_ID)
      const sheet = await copySheet(doc)
      await updateSheet(sheet, { title }, genOrderPortCells(payload, rootGetters['product/productDict'], rootGetters['product/productUidDict']), "A7:F29")
      const sheetURL = `https://docs.google.com/spreadsheets/d/${process.env.VUE_APP_ECOMMERCE_PORT_DOC_ID}/edit#gid=${sheet.sheetId}`
      await database.table('orders').where('orders_id', '=', orders_id).update({ order_port_doc_url: sheetURL })
      commit('update', { name: 'ordersList', data: { id: orders_id, order_port_doc_url: sheetURL } })
      return { docURL: sheetURL }
    },
    async logOperation({ commit, rootState }, payload) {
      const { orders, operation } = payload
      const promises = orders.map(o => new Promise(async(res, rej) => {
        try {
          if (operation === 'RECEIPT') {
            await database.table('orders').where('orders_id', '=', o.orders_id).update({ order_receipt_id: o.order_receipt_id })
          }
          if (operation === 'SHIPPED') {
            await database.table('orders').where('orders_id', '=', o.orders_id).update({
              order_logistics_state: JSON.stringify([
                ...o.order_logistics_state,
                { code:"900", update_time: moment().format("YYYY\/MM\/DD HH:mm:ss") }
              ])
            })
          }
          await database.table('order_log').set({
            orders_id: o.orders_id,
            admin_id: rootState.login.currentUser.admin_id,
            order_log_operation: operation,
            order_log_create_at: database.FieldValue.serverTimestamp()
          })
          res()
        } catch (error) {
          rej(error)
        }
      }))

      try {
        await Promise.all(promises)
        commit('batchLogOperation', { orders, operation })
        return { state: 200 }
      } catch (error) {
        return { state: 500, msg: error }
      }
    }
  },
  getters: {
    returnListCount({ returnList }) {
      if (returnList.length) {
        return returnList.filter(e => e.return_order_state === 'RETURN_APPLY').length
      }
      return 0
    }
  }
}

const addressRegex = /\W{2}[縣市]\W{2,}[鄉鎮市區]/g
const genOrderPortCells = (order, productDict, productUidDict) => {
  let raw = 12
  const productCells = order.order_products.reduce((acc, product) => {
    raw += 1
    const productInfo = productDict[product.product_id]
    return { ...acc,
      [`A${raw}`]: productInfo.product_uid,
      [`B${raw}`]: productInfo.product_name_zh,
      [`D${raw}`]: product.product_price,
      [`E${raw}`]: product.product_quantity,
      [`F${raw}`]: product.product_price * product.product_quantity,
    }
  }, {})
  const giveawayCells = order.order_coupon_used.filter(c => c.coupon_type === 'GIVEAWAY').reduce((acc, coupon) => {
    return { 
      ...acc,
      ...coupon.coupon_items.reduce((innerAcc, item) => {
        const productInfo = productUidDict[item.product_uid]
        raw+=1
        return {
          ...innerAcc,
          [`A${raw}`]: productInfo.product_uid,
          [`B${raw}`]: productInfo.product_name_zh,
          [`D${raw}`]: '贈',
          [`E${raw}`]: 1,
          [`F${raw}`]: '贈',
        }
      }, {})
    }
  }, {})
  return {
    'B7': order.key_id, //訂單編號
    'E7': order.order_logistics_info.ReceiverName , //收件人
    'B8': order.order_logistics_info[order.order_logistics_type === 'CVS' ? 'CVSPaymentNo' : 'BookingNote'], //物流編號
    'E8': order.order_logistics_info.ReceiverCellPhone, //收件人電話
    'B9': moment().format('YYYY/MM/DD'), //出貨日期
    'E9': order.order_logistics_type === 'CVS'
      ? `${order.order_logistics_subtype === 'FAMIC2C' ? '全家' : '7-11'} - ${order.order_logistics_cvs_info.CVSStoreName}`
      :order.order_logistics_info.ReceiverAddress && order.order_logistics_info.ReceiverAddress.match(addressRegex)[0], //收件縣市
    'E10': order.order_logistics_type === 'CVS'
      ? order.order_logistics_cvs_info.CVSAddress
      : order.order_logistics_info.ReceiverAddress.replace(addressRegex, ''), //收件地址,
    ...productCells, //商品資訊
    ...giveawayCells, //贈品資訊
    'F25': order.order_discount //折扣
}
}
