import Vue from 'vue'
import Message from './Message.vue'
import Confirm from './Confirm.vue'
import Prompt from './Prompt.vue'

const methodType = ['success', 'warning', 'info', 'error']
const MsgVue = new Vue({
  data() {
    return {
      text: 'default'
    }
  },
  methods: {
    ...methodType.reduce((acc, type) => ({
      ...acc,
      [`msg_${type}`]: function(text, timeout) {
        this.$children[0].showMsg(text, type, timeout)
      },
      [`confirm_${type}`]: function(title, text, callback) {
        this.$children[1].showConfirm(title, text, callback, type)
      },
      [`prompt_${type}`]: function(title, callback) {
        this.$children[2].showPrompt(title, callback, type)
      }
    }), {}),
  },
  render: h => (
    <div>
      <Message />
      <Confirm />
      <Prompt />
    </div>
  )
}).$mount('#k-message')

export default {
  install(Vue, options) {
    Vue.prototype.$message = methodType.reduce((acc, type) => ({
      ...acc,
      [type]: function(text, timeout) {
        MsgVue[`msg_${type}`](text, timeout)
      }
    }), {})
    Vue.prototype.$confirm = methodType.reduce((acc, type) => ({
      ...acc,
      [type]: function({ title, text="", callback=void 0 }) {
        MsgVue[`confirm_${type}`](title, text, callback)
      }
    }), {})
    Vue.prototype.$prompt = methodType.reduce((acc, type) => ({
      ...acc,
      [type]: function({ title, callback=void 0 }) {
        MsgVue[`prompt_${type}`](title, callback)
      }
    }), {})
  }
}
