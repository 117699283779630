<template>
  <k-container class="view-register-container">
    <h1>註冊會員</h1>
    <k-card>
      <k-input v-model="account" label="帳號" required></k-input>
      <k-input v-model="password" label="密碼" required></k-input>
      <k-input v-model="lineId" label="Line ID"></k-input>
      <k-button theme="primary" @click="handleSubmit" block>註冊</k-button>
    </k-card>
  </k-container>
</template>

<script>
import KCard from '@/components/Card'
import { KInput } from '@/components/Form'
import KButton from '@/components/Button'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      account: "",
      password: "",
      lineId: ""
    }
  },
  computed: {
    
  },
  methods: {
    ...mapActions('user', ['registerUser']),
    async handleSubmit() {
      if (!this.account || !this.password) return this.$message.warning('請確認帳號與密碼皆有填妥')
      const res = await this.registerUser({ account: this.account, password: this.password, lineId: this.lineId })
      if (res.status === 200) {
        this.$message.success('已完成註冊')
        this.account = ''
        this.password = ''
        this.lineId = ''
      }
      if (res.status === 422) {
        this.$message.warning('此帳號已存在')
      }
    }
  },
  components: {
    KCard, KInput, KButton
  } 
}
</script>
