<script>
export default {
  name: 'KDescriptionsItem',
  props: {
    label: String,
    span: {
      type: [Number, String],
      default: 1
    }
  },
  render(h) {
    const { span } = this.$props
    return (
        <td colSpan={span}>
          <div>
            <span class="label">{this.label}</span>
            <span class="value">{this.$slots.default}</span>
          </div>
        </td>
    )
  }
}
</script>
