<template>
  <div class="k-table-wrapper">
    <table :class="classes">
      <p
        v-if="$slots['table-caption']"
        :class="{
          'table-caption': true,
          'footer-caption': footerCaption
        }"
      >
        <slot name="table-caption"></slot>
      </p>
      <thead>
        <tr>
          <th
            v-for="f, index in parseFields"
            :key="f.key || `header_${index}`"
          >
            <template v-if="f.fieldIndex !== 'selection'">
              <slot
                name="cell(head)"
                v-bind="{ label: f.label }"
              >{{ f.label }}</slot>
              <span
                v-if="f.sorter"
                :class="{
                  'table-sorter': true,
                  'focus': focusSorter && focusSorter.fieldIndex === f.fieldIndex,
                  'desc': focusSorter && focusSorter.fieldIndex === f.fieldIndex && !focusSorter.direction
                }"
                @click="changeFocusSorter(f.fieldIndex)"
              >
                <k-icon icon="arrow-down"></k-icon>
              </span>

              <k-dropdown
                v-if="f.searchable"
                class="table-filter"
                :ref="`overlay_${f.fieldIndex}`"
              >
                <span>
                  <k-icon
                    :theme="search[f.fieldIndex] ? 'danger' : null"
                    icon="search"
                  ></k-icon>
                </span>

                <div
                  class="table-filter-overlay"
                  slot="overlay"
                >
                  <k-input
                    v-model="search[f.fieldIndex]"
                    :label="`搜尋${f.label}`"
                    placeholder="搜尋"
                  ></k-input>
                  <span class="table-filter-button">
                    <k-button
                      @click="hideOverlay(f.fieldIndex)"
                      theme="info"
                      varient="ghost"
                    >確定</k-button>
                    <k-button
                      class="table-filter-reset"
                      @click="resetSearch(f.fieldIndex)"
                      theme="danger"
                      varient="ghost"
                    >重置</k-button>
                  </span>
                </div>
              </k-dropdown>

              <k-dropdown
                v-if="f.filters"
                class="table-filter"
                :ref="`overlay_${f.fieldIndex}`"
              >
                <span>
                  <k-icon
                    :theme="feildFilters[f.fieldIndex] && feildFilters[f.fieldIndex].length ? 'danger' : null"
                    icon="funnel-fill"
                  />
                </span>

                <div
                  class="table-filter-overlay"
                  slot="overlay"
                >
                  <k-menu>
                    <k-menu-item
                      v-for="item in f.filters"
                      :key="item.value"
                    >
                      <label>
                        <input
                          type="checkbox"
                          :value="item.value"
                          v-model="feildFilters[f.fieldIndex]"
                        >
                        {{ item.text }}
                      </label>
                    </k-menu-item>
                  </k-menu>
                  <span class="table-filter-button">
                    <k-button
                      @click="hideOverlay(f.fieldIndex)"
                      theme="info"
                      varient="ghost"
                    >確定</k-button>
                    <k-button
                      class="table-filter-reset"
                      @click="resetFilter(f.fieldIndex)"
                      theme="danger"
                      varient="ghost"
                    >重置</k-button>
                  </span>
                </div>
              </k-dropdown>
            </template>

            <!-- <input v-else type="checkbox" v-model="isCheckAll"> -->
            <k-checkbox
              v-else
              v-model="isCheckAll"
            ></k-checkbox>
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-if="$slots['table-busy']">
          <tr>
            <td>
              <slot name="table-busy"></slot>
            </td>
          </tr>
        </template>

        <template v-else>
          <tr
            v-for="i, index in paginationItem"
            :key="`i_${index}`"
          >
            <td
              v-for="f, fIndex in parseFields"
              :key="fIndex"
            >
              <slot
                v-if="f.fieldIndex !== 'selection'"
                :name="[`cell(${f.fieldIndex})`]"
                v-bind="{ item: i, value: i[f.fieldIndex], index: index }"
              >
                {{ i[f.fieldIndex] || '' }}
              </slot>

              <!-- <input v-else type="checkbox" :value="i" v-model="rowSelected"> -->
              <k-checkbox
                v-else
                :value="i"
                v-model="rowSelected"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <k-button
      v-if="rowSelection && rowSelected.length"
      class="row-select-button"
      v-bind="rowSelectionButtonProps"
      @click="$emit('check', rowSelected)"
      block
    />
    <k-pagination
      v-if="pagination"
      v-bind="pagination"
      :pages="pages"
      v-model="pagination.current"
    ></k-pagination>
  </div>
</template>

<script>
import KIcon from '../components/Icon'
import KDropdown from '../components/Dropdown'
import { KMenu, KMenuItem } from '../components/Menu'
import { KInput, KCheckbox } from '../components/Form'
import KButton from '../components/Button'
import KPagination from '../components/Pageination'

export default {
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    hover: Boolean,
    borderless: Boolean,
    bordered: Boolean,
    footerCaption: Boolean,
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    rowSelection: [Array, Boolean],
    rowSelectionButtonProps: {
      type: Object,
      default: () => ({
        theme: 'primary',
        label: '確認'
      })
    }
  },
  mounted() {
    this.search = this.fields.filter(f => f.searchable).reduce((acc, f) => ({ ...acc, [f.fieldIndex]: null }), {})
    this.feildFilters = this.fields.filter(f => !!f.filters).reduce((acc, f) => ({ ...acc, [f.fieldIndex]: [] }), {})
    this.fields.forEach((f, index) => {
      if (f.filters === true) {
        this.fields[index].filters = this.items.reduce((acc, item) => {
          const value = item[this.fields[index].fieldIndex]
          if (acc.map(e => e.value).indexOf(value) === -1) acc.push({ value, text: value })
          return acc
        }, [])
      }
    })
    if (this.rowSelection && this.rowSelection.length) this.rowSelected = this.rowSelection.map(e => e)
  },
  data() {
    return {
      focusSorter: null,
      search: {},
      feildFilters: {},
      rowSelected: [],
      isCheckAll: false
    }
  },
  methods: {
    fieldTypeParser(field) {
      if (typeof field === 'string') return { fieldIndex: field, label: field };
      return { ...field };
    },
    changeFocusSorter(fieldIndex) {
      if (this.focusSorter && this.focusSorter.fieldIndex === fieldIndex) {
        if (!this.focusSorter.direction) this.focusSorter = null
        else this.focusSorter = { ...this.focusSorter, direction: false }
      }
      else {
        const focusField = this.parseFields.filter(f => f.fieldIndex === fieldIndex)[0]
        this.focusSorter = {
          fieldIndex,
          sorter: focusField.sorter,
          direction: true
        }
      }
    },
    resetFilter(fieldIndex) {
      this.feildFilters[fieldIndex] = []
    },
    hideOverlay(fieldIndex) {
      this.$refs[`overlay_${fieldIndex}`][0].hide()
    },
    resetSearch(fieldIndex) {
      this.search[fieldIndex] = null
    },
    checkAll(bool) {
      this.rowSelected = bool ? this.items.map(e => e) : []
    },
    resetRowSelected() {
      this.checkAll(false)
    }
  },
  computed: {
    classes() {
      return {
        'k-table': true,
        'table-hover': this.hover,
        'table-borderless': this.borderless,
        'table-bordered': this.bordered,
      }
    },
    parseFields() {
      if (this.rowSelection) {
        return [
          { key: 'selection', fieldIndex: 'selection', label: '' },
          ...this.fields
        ].map(f => typeof f === 'string' ? { fieldIndex: f, label: f } : { ...f })
      }
      return this.fields.map(f => typeof f === 'string' ? { fieldIndex: f, label: f } : { ...f })
    },
    filteredItem() {
      let formatItem = this.items.map(i => ({ ...i }))
      if (Object.keys(this.search).length) {
        formatItem = Object.entries(this.search).reduce((acc, [field, filterValue]) => {
          return filterValue ? acc.filter(i => i[field].includes(filterValue)) : acc
        }, formatItem)
      }
      if (Object.keys(this.feildFilters).length) {
        formatItem = Object.entries(this.feildFilters).reduce((acc, [field, filterValue]) => {
          return filterValue.length ? acc.filter(i => filterValue.includes(i[field])) : acc
        }, formatItem)
      }
      if (this.focusSorter) {
        const { fieldIndex, sorter } = this.focusSorter
        const directiveSorter = this.focusSorter.direction
          ? (a, b) => sorter(a[fieldIndex], b[fieldIndex])
          : (b, a) => sorter(a[fieldIndex], b[fieldIndex])
        formatItem.sort(directiveSorter)
      }
      return formatItem
    },
    pages() {
      return Math.ceil(this.filteredItem.length / (this.pagination.limit || 10));
    },
    paginationItem() {
      if (this.pagination) {
        return this.filteredItem.slice(
          ((this.pagination.current || 1) - 1) * (this.pagination.limit || 10),
          (this.pagination.current || 1) * (this.pagination.limit || 10)
        );
      }
      return this.filteredItem
    }
  },
  watch: {
    isCheckAll(newVal) {
      this.checkAll(newVal)
    }
  },
  components: {
    KIcon, KDropdown, KInput, KButton, KPagination, KMenu, KMenuItem, KCheckbox
  }
}
</script>
