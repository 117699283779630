<template>
  <div :class="classes" @click="$emit('click')">
    <header v-if="header || $slots.header" @click="toggleClose" :class="{collapsible: collapsible || null}">
      <slot name="header">{{ header }}</slot>
    </header>

    <main class="card-body" :style="styles">
      <section ref="content" :class="{'content-wrapper': true, 'collapsible': collapsible}">
        <slot name="title"><h2 v-if="title">{{ title }}</h2></slot>
        <slot></slot>
      </section>
    </main>
    
    <footer v-if="footer || $slots.footer">
      <slot name="footer">{{ footer }}</slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'k-card',
  props: {
    noShadow: Boolean,
    title: {
      type: String
    },
    header: {
      type: String
    },
    footer: {
      type: String
    },
    outline: {
      type: String
    },
    align: {
      type: String
    },
    defaultOpen: Boolean,
    collapsible: Boolean
  },
  mounted() {
    if (this.collapsible) {
      this.observer = new MutationObserver(mutations => this.offsetHeight = this.$refs.content.offsetHeight)
      this.observer.observe(
        this.$refs.content,
        { attributes: false, childList: true, characterData: false, subtree: true }
      );
    }
    this.isClose = this.defaultOpen ? false : true
    this.offsetHeight = this.$refs.content.offsetHeight
  },
  beforeDestroy() {
    if (this.collapsible) this.observer.disconnect()
  },
  data() {
    return {
      isClose: false,
      offsetHeight: 'auto'
    }
  },
  computed: {
    classes() {
      return {
        'k-card': true,
        [`outline--${this.outline}`]: !!this.outline,
        'noShadow': !!this.noShadow,
        'collapsible': !!this.collapsible,
        'close': this.isClose && this.collapsible,
        'show': !this.isClose && this.collapsible,
      }
    },
    styles() {
      return {
        'text-align': this.align || 'left',
        'max-height': this.collapsible
          ? this.isClose 
            ? 0
            : `${this.offsetHeight+32}px`
          : null

      }
    }
  },
  methods: {
    toggleClose() {
      if (this.collapsible) this.isClose = !this.isClose
    }
  },
}
</script>
