<script>
import KButton from '@/components/Button'
import KModal from '@/components/Modal'

export default {
  name: 'K-Confirm',
  data() {
    return {
      show: false,
      text: '',
      title: '',
      type: null,
      callback: void 0,
      typeIcon: {
        success: 'bi-check-circle-fill',
        warning: 'bi-exclamation-octagon-fill',
        error: 'bi-x-circle-fill',
        info: 'bi-info-circle-fill',
      }
    }
  },
  methods: {
    showConfirm(title, text, callback, type) {
      this.show = true
      this.title = title
      this.callback = callback
      this.type = type
      this.text = text
    },
    handleClick(bool) {
      this.show = false
      this.callback(bool)
    }
  },
  computed: {
    classes() {
      return {
        'k-confirm': true,
        'show': this.show,
      }
    },
    spanClasses() {
      return {
        [`confirm--${this.type}`]: this.type
      }
    }
  },
  render(h) {
    return (
      <KModal class="k-confirm" show={this.show} closable={false} hideHeader hideFooter>
        <span class={this.spanClasses}>
          <i class={`bi ${this.typeIcon[this.type]}`} />
          <b>{this.title}</b>
          <p>{this.text}</p>
        </span>
        <KButton label="取消" theme="light" onClick={() => this.handleClick(false)} />
        <KButton label="確認" theme="warning" onClick={() => this.handleClick(true)} />
      </KModal>
    )
  },
  component: {
    KModal, KButton, 
  }
}
</script>
