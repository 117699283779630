<template>
  <div class="k-layout-basic">
    <k-sidebar :menus="computedMenu">
      <template #brand>
        <k-icon icon="x-diamond-fill" />Win Bar
      </template>
    </k-sidebar>

    <section class="k-wraper">
      <k-header />
      <slot></slot>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { KSidebar, KHeader } from './components';

export default {
  async mounted() {
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      sidebarMenus: menus,
    }
  },
  computed: {
    ...mapState('login', ['currentUser']),
    computedMenu() {
      if (this.currentUser && this.currentUser.auth_id) {
        switch (this.currentUser.auth_id) {
          case '1': return this.sidebarMenus
          case '4': return this.sidebarMenus.slice(0,4)
          case '2': return this.sidebarMenus.slice(0,4)
          case '3': return [this.sidebarMenus[0], this.sidebarMenus[2], this.sidebarMenus[3]]
          default: return [this.sidebarMenus[0], this.sidebarMenus[2], this.sidebarMenus[3]]
        }
      }
      return [this.sidebarMenus[0], this.sidebarMenus[2], this.sidebarMenus[3]]
    }
  },
  components: {
    KSidebar,
    KHeader
  }
}

const menus = [
  {
    type: 'title',
    label: '管理'
  },
  {
    type: 'item',
    to: '/admin',
    label: '管理員列表',
    icon: 'person-gear'
  },
  {
    type: 'item',
    to: '/user',
    label: '所有會員',
    icon: 'person-lines-fill'
  },
  {
    type: 'item',
    to: '/timelog',
    label: '儲值紀錄',
    icon: 'person-lines-fill'
  },
  {
    type: 'item',
    to: '/register',
    label: '註冊會員',
    icon: 'person-lines-fill'
  },
]
</script>
