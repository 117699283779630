<template>
  <k-container class="view-user-container">
    <h1>會員管理</h1>
    <k-card>
      <template #header>Accounts</template>
      <k-table :items="userWithCompany" :fields="fields" hover footerCaption :pagination="userWithCompany.length > 10 ? pagination : false">

        <template #cell(user_line_id)="{value}">
          {{ value || '未填寫' }}
        </template>

        <template #cell(user_company)="{value}">
          <k-tag v-if="value" :theme="companyColorPlate[value]">{{ value }}</k-tag>
          <span v-else>-</span>
        </template>

        <template #cell(user_agent)="{value}">
          {{ adminDict[value] ? adminDict[value].admin_name : '-' }}
        </template>

        <template #cell(user_exp_at)="{value}">
          {{ value ? moment(value*1000).diff(moment(), 'days')+'天' : '-' }}
        </template>
        
        <template #cell(user_token_refresh_at)="{value}">
          {{ value ? moment(value*1000).format('YYYY-MM-DD') : '-' }}
        </template>

        <template #cell(detail)="data">
          <k-button v-if="currentUser.edit_data === '1'" theme="info" varient="ghost" @click="changeFocusUser(data.item.user_id, 'time')">儲值時間</k-button>
          <k-button v-if="currentUser.edit_data === '1'" theme="warning" varient="ghost" @click="changeFocusUser(data.item.user_id, 'info')">修改資訊</k-button>
          <!-- <k-button theme="danger" varient="ghost" @click="changeFocusUser(data.item.user_id)">封鎖帳號</k-button> -->
        </template>
      </k-table>
    </k-card>

    <k-modal title="用戶加時" :show.sync="timeShow" :width="600" hideFooter >
      <div v-if="focusUserInfo">
        <p>操作用戶：{{ focusUserInfo.user_account }} | 請選擇或輸入要增加的時間與訂單金額</p>
        <div class="button-group">
          <k-button theme="light" @click="addTIme('30', '10000')">加時30天</k-button>
          <k-button theme="light" @click="addTIme('90', '25000')">加時90天</k-button>
          <k-button theme="light" @click="addTIme('180', '48000')">加時180天</k-button>
        </div>

        <k-divider></k-divider>

        <div class="input-wrapper">
          <k-input placeholder="加時" type="number" v-model="expTime" addonBefore="加時" addonAfter="天"></k-input>
          <k-input placeholder="金額" type="number" v-model="price" addonBefore="金額" addonAfter="元"></k-input>
          <k-button theme="light" @click="addExpTime">依輸入值加時</k-button>
        </div>
      </div>
    </k-modal>

    <k-modal title="編輯用戶資訊" :show.sync="infoShow" :width="600" hideFooter >
      <div v-if="focusUserInfo">
        <k-form :questionConfig.sync="userInfoQuestion" :defaultValues="focusUserCache" @submit="updateUser" :submitLoading.sync="infoLoading">
          <template #after(user_line_id)>
            <k-divider type="center">選擇所屬代理</k-divider>
            <k-card v-for="company in ['AC01', 'AC02', 'AC03', 'AC04']" :title="company" :key="`admin_company_${company}`" outline="light">
              <div>
                <k-button
                  :theme="focusUserCache.user_agent === admin.admin_id ? 'success' : 'secondary'"
                  v-for="admin in agentDict[company]"
                  :key="`agent_${admin.admin_id}`"
                  @click="chooseAgent(admin)"
                >
                  {{ admin.admin_name }}
                </k-button>
              </div>
            </k-card>
          </template>
        </k-form>
      </div>
    </k-modal>
  </k-container>
</template>

<script>
import KTable from '@/components/Table'
import KCard from '@/components/Card'
import KTag from '@/components/Tag'
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import KDivider from '@/components/Divider'
import { KInput, KForm } from '@/components/Form'
import { KDescriptions, KDescriptionsItem } from '@/components/Descriptions'
import AdminCard from './components/AdminCard'
import { mapActions, mapGetters, mapState } from 'vuex'
import { timestampParse, euqalObject } from '@/utils'
import moment from 'moment'

export default {
  name: 'User',
  mounted() {
    this.$store.dispatch('admin/query')
    this.$store.dispatch('user/query')
  },
  data() {
    return {
      fields: [
        { key: 'user_account', fieldIndex: 'user_account', label: '用戶帳號', searchable: true,
          sorter: (a, b) => ('' + a).localeCompare(b)
        },
        { key: 'user_line_id', fieldIndex: 'user_line_id', label: 'Line ID', searchable: true},
        { key: 'user_exp_at', fieldIndex: 'user_exp_at', label: '剩餘時間',
          sorter: (a, b) => b - a
        },
        { key: 'user_company', fieldIndex: 'user_company', label: '隸屬公司',
          filters: [
            { value: 'AC01', text: 'AC01' }, 
            { value: 'AC02', text: 'AC02' }, 
            { value: 'AC03', text: 'AC03' }, 
            { value: 'AC04', text: 'AC04' }
          ]
        },
        { key: 'user_agent', fieldIndex: 'user_agent', label: '隸屬代理'
        },
        { key: 'user_token_refresh_at', fieldIndex: 'user_token_refresh_at', label: '上次登入', sorter: (a, b) => b - a },
        { key: 'detail', fieldIndex: 'detail', label: '' }, 
      ],
      items: [
        { user_id: 'k001' ,user_name: 'glove', user_email: 'glovecoding@gmail.com', user_age: 24, user_gender: 'Male' },
        { user_id: 'k002' ,user_name: 'paboya', user_email: 'paboya@gmail.com', user_age: 69, user_gender: 'Female' },
        { user_id: 'k003' ,user_name: 'dexter', user_email: 'dexisbitch@gmail.com', user_age: 25, user_gender: 'Male' },
        { user_id: 'k004' ,user_name: 'alexander', user_email: 'alex058188@gmail.com', user_age: 24, user_gender: 'Male' },
        { user_id: 'k005' ,user_name: 'chikenBreast', user_email: 'welovechikenbreast@gmail.com', user_age: 24, user_gender: 'Male' }, 
      ],
      companyColorPlate: { 'AC01': 'info', 'AC02': 'warning', 'AC03': 'success', 'AC04': 'primary' },
      buttonNum: 1,
      show: false,
      pagination: {
        current: 1,
        limit: 10
      },
      focusUserInfo: null,
      focusUserCache: null,
      timeShow: false,
      infoShow: false,
      infoLoading: false,
      expTime: '0',
      price: '0',
      userInfoQuestion,
    }
  },
  computed: {
    ...mapState('login', ['currentUser']),
    ...mapState('user', ['userList']),
    ...mapGetters('user', ['userWithCompany']),
    ...mapGetters('admin', ['adminDict', 'agentDict'])
  },
  methods: {
    ...mapActions('user', ['userAddExpTime', 'updateUserInfo']),
    ...mapActions('admin', ['addTimeLog']),
    timestampParse,
    moment(value) {
      return value ? moment(value) : moment()
    },
    onCopy() {
      this.$message.success('已複製到剪貼簿')
    },
    changeFocusUser(id, mode) {
      console.log(this.userList);
      const userIndex = this.userList.map(i => i.user_id).indexOf(id)
      if (mode === 'time' && (!this.userList[userIndex] || !this.userList[userIndex].user_company)) {
        return this.$message.warning('請先設定此會員的所屬代理')
      }
      if (mode === 'info') {
        this.focusUserCache = JSON.parse(JSON.stringify(this.userList[userIndex]))
      }
      this.focusUserInfo = { ...this.userList[userIndex] }
      this[`${mode}Show`] = true
    },
    addTIme(time, price) {
      this.expTime = time
      this.price = price
    },
    async addExpTime() {
      if (this.expTime !== '0' || this.price !== '0') {
        console.log(this.focusUserInfo.user_exp_at ? parseInt(this.expTime)*3600*24 +parseInt(this.focusUserInfo.user_exp_at) : moment().unix() + parseInt(this.expTime)*3600*24);
        const payload = { 
          time: this.focusUserInfo.user_exp_at ? parseInt(this.expTime)*3600*24 +parseInt(this.focusUserInfo.user_exp_at) : moment().unix() + parseInt(this.expTime)*3600*24,
          user_id: this.focusUserInfo.user_id
        }
        const res = await this.userAddExpTime(payload)
        const timeRes = await this.addTimeLog({
          user_id: this.focusUserInfo.user_id ,
          time_log_company: this.focusUserInfo.user_company,
          time_log_agent: this.focusUserInfo.user_agent,
          time_log_value: parseInt(this.expTime)*3600*24,
          time_log_price: this.price
        })
        console.log(res);
        console.log(timeRes);
        if (res.status === 200) {
          this.focusUserInfo = null
          this.expTime = '0'
          this.timeShow = false
        }
      } else {
        this.$message.warning('請確定加時與金額皆不為0')
      }
    },
    chooseAgent(agent) {
      this.focusUserCache = { ...this.focusUserCache, user_agent: agent.admin_id, user_company: agent.admin_company }
    },
    async updateUser(formData) {
      const newInfo = { ...this.focusUserCache, ...formData.values }
      const changeState = euqalObject(this.focusUserInfo, newInfo)
      if (!changeState) {
        this.$confirm.info({
          title: "確定要進行會員資料改動嗎？",
          callback: async (ensure) => {
            if (ensure) {
              await this.updateUserInfo(newInfo)
              this.$message.success('已修改')
            }
          }
        })
      } else {
        this.$message.info('未作任何改動') 
      }
      this.focusUserCache = {}
      this.focusUserInfo = {}
      this.infoShow = false
    },
    showModal() {
      this.show = true
    }
  },
  components: {
    AdminCard,
    KTable, KCard, KTag, KButton, KModal, KDescriptions, KDescriptionsItem, KInput, KDivider, KForm
  }
}

const userInfoQuestion = [
  {
    questionType: 'k-input',
    name: 'user_account',
    placeholder: "Enter...",
    label: "會員帳號",
    type: 'text',
    theme: "light",
    required: true,
    title: '會員帳號',
    readonly: true
  },
  {
    questionType: 'k-input',
    name: 'user_password',
    placeholder: "Enter...",
    label: "新密碼",
    type: 'text',
    theme: "light",
    title: '新密碼'
  },
  {
    questionType: 'k-input',
    name: 'user_line_id',
    placeholder: "Enter...",
    label: "Line ID",
    type: 'text',
    theme: "light",
    title: 'Line ID'
  },
  {
    questionType: 'k-btn',
    label: "修改",
    theme: "primary",
    type: "submit"
  }
]
</script>
