<template>
  <div :class="classes" @click="$emit('onClick')">
    <label v-if="label" :for="uid"><span v-if="required && !hideRequiredMark">＊ </span>{{ label }}</label>
    <section>
      <div v-if="addonBefore || $slots['addonBefore']" class="addon-before">
        <slot name="addonBefore">{{ addonBefore }}</slot>
      </div>
      <div class="input">
        <input 
          ref="input"
          :id="uid" :type="type || 'text'"
          :value="value"
          :style="styles"
          :placeholder="placeholder || `設定${label}`"
          :required="required"
          :readonly="readonly"
          :step="step"
          :pattern="pattern"
          :minlength="minlength"
          :maxlength="maxlength"
          :title="title"
          @invalid="handleInvalid"
          @input="$emit('input', $event.target.value); $emit('change', $event.target.value)"
          @keyup.enter="$emit('enter')"
          @click="handleClick"
        >
        <span class="extra-icon">
          <slot name="icon"></slot>
        </span>
      </div>
      <div v-if="addonAfter || $slots['addonAfter']" class="addon-after ">
        <slot name="addonAfter">{{ addonAfter }}</slot>
      </div>
    </section>
    
    <small v-if="extra || $slots.extra" class="extra"><slot name="extra">{{ extra }}</slot></small>
    <span v-if="isInvalid && invalidContext" class="extra invalid">
      <k-icon icon="exclamation-triangle" theme="danger" />
      {{invalidContext}}
    </span>
  </div>
</template>

<script>
import formMixin from '@/mixin/form'
import { gen8ID } from '@/utils'

export default {
  mixins: [formMixin],
  mounted() {
    this.uid = `k-input-group-${gen8ID()}`
  },
  props: {
    placeholder: String,
    title: String,
    value: String,
    label: String,
    extra: String,
    theme: String,
    addonBefore: String,
    addonAfter: String,
    type: String,
    required: Boolean,
    hideRequiredMark: Boolean,
    step: [Number, String],
    minlength: [Number, String],
    maxlength: [Number, String],
    pattern: String,
    patternExample: {
      type: String,
      default: ''
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uid: '',
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e)
    }
  },
  computed: {
    classes() {
      return {
        'k-input': true,
        [`varient--${this.theme}`]: !!this.theme,
        [`type--${this.type}`]: !!this.type,
        'invalid': this.isInvalid
      }
    },
    styles() {
      return {
        borderTopRightRadius: this.addonAfter || this.$slots['addonAfter'] ? 0 : '4px',
        borderBottomRightRadius: this.addonAfter || this.$slots['addonAfter'] ? 0 : '4px',
      }
    },
    synVal:{
      get(){
        return this.value
      },
      set(val){
        this.$emit('update:value',val);
      }
    }

  },
  watch: {
    value(newValue, oldValue) {
      if (!oldValue) this.handleValid()
    }
  }
}
</script>
